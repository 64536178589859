@import '../variables/colors.scss';

$background: #eee;

$line_width: 10px;
$line_space: $line_width * .5;


html,
body {
  width: 100%;
  height: 100%;
}
.flex-container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
body {
  background-color: $background;
}
.unit {
  text-align: center;
}
.heart {
  position: relative;
  //font-size: 16px;
  width: $line_width * 9 + $line_space * 8;
}

.heart-loading {
  position: absolute;
  bottom:36%;
  left:51%;
  color:$mainColor;
  font-size: 14px;
  font-weight: bolder;
  transform: translate(-50%,-50%);
  text-transform: uppercase;
  letter-spacing: 10px;

}

[class*="heart-piece-"] {
  position: absolute;
  top: -$line_width *.5;
  width: $line_width;
  height: $line_width;
  border-radius: $line_width * .5;
}
.heart-piece-4 {
  -webkit-animation: piece-4 3.2s infinite;
  animation: piece-4 3.2s infinite;
}
.heart-piece-3,
.heart-piece-5 {
  -webkit-animation: piece-3 3.2s infinite;
  animation: piece-3 3.2s infinite;
}
.heart-piece-2,
.heart-piece-6 {
  -webkit-animation: piece-2 3.2s infinite;
  animation: piece-2 3.2s infinite;
}
.heart-piece-1,
.heart-piece-7 {
  -webkit-animation: piece-1 3.2s infinite;
  animation: piece-1 3.2s infinite;
}
.heart-piece-0,
.heart-piece-8 {
  -webkit-animation: piece-0 3.2s infinite;
  animation: piece-0 3.2s infinite;
}
.heart-piece-0 {
  left: 0;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  background-color: $mainColor;
}
.heart-piece-1 {
  left: ($line_width + $line_space) * 1;
  -webkit-animation-delay: 0.15s * 1;
  animation-delay: 0.15s * 1;
  background-color: $mainColor;
}
.heart-piece-2 {
  left: ($line_width + $line_space) * 2;
  -webkit-animation-delay: 0.15s * 2;
  animation-delay: 0.15s * 2;
  background-color: $mainColor;
}
.heart-piece-3 {
  left: ($line_width + $line_space) * 3;
  -webkit-animation-delay: 0.15s * 3;
  animation-delay: 0.15s * 3;
  background-color: $mainColor;
}
.heart-piece-4 {
  left: ($line_width + $line_space) * 4;
  -webkit-animation-delay: 0.15s * 4;
  animation-delay: 0.15s * 4;
  background-color: $mainColor;
}
.heart-piece-5 {
  left: ($line_width + $line_space) * 5;
  -webkit-animation-delay: 0.15s * 5;
  animation-delay: 0.15s * 5;
  background-color: $mainColor;
}
.heart-piece-6 {
  left: ($line_width + $line_space) * 6;
  -webkit-animation-delay: 0.15s * 6;
  animation-delay: 0.15s * 6;
  background-color: $mainColor;
}
.heart-piece-7 {
  left: ($line_width + $line_space) * 7;
  -webkit-animation-delay: 0.15s * 7;
  animation-delay: 0.15s * 7;
  background-color: $mainColor;
}
.heart-piece-8 {
  left: ($line_width + $line_space) * 8;
  -webkit-animation-delay: 0.15s * 8;
  animation-delay: 0.15s * 8;
  background-color: $mainColor;
}
@-webkit-keyframes piece-4 {
  0%, 10%, 90%, 100% {
    height: $line_width;
    top: -$line_width * .5;
  }
  45%, 55% {
    height: $line_width * 9.4;
    top: -$line_width * 2.3;
  }
}
@keyframes piece-4 {
  0%, 10%, 90%, 100% {
    height: $line_width;
    top: -$line_width * .5;
  }
  45%, 55% {
    height: $line_width * 9.4;
    top: -$line_width * 2.3;
  }
}
@-webkit-keyframes piece-3 {
  0%, 10%, 90%, 100% {
    height: $line_width;
    top: -$line_width * .5;
  }
  45%, 55% {
    height: $line_width * 9;
    top: -$line_width * 3.1;
  }
}
@keyframes piece-3 {
  0%, 10%, 90%, 100% {
    height: $line_width;
    top: -$line_width * .5;
  }
  45%, 55% {
    height: $line_width * 9;
    top: -$line_width * 3.1;
  }
}
@-webkit-keyframes piece-2 {
  0%, 10%, 90%, 100% {
    height: $line_width;
    top: -$line_width * .5;
  }
  45%, 55% {
    height: $line_width * 8;
    top: -$line_width * 3.7;
  }
}
@keyframes piece-2 {
  0%, 10%, 90%, 100% {
    height: $line_width;
    top: -$line_width * .5;
  }
  45%, 55% {
    height: $line_width * 8;
    top: -$line_width * 3.7;
  }
}
@-webkit-keyframes piece-1 {
  0%, 10%, 90%, 100% {
    height: $line_width;
    top: -$line_width * .5;
  }
  45%, 55% {
    height: $line_width * 6;
    top: -$line_width * 3.1;
  }
}
@keyframes piece-1 {
  0%, 10%, 90%, 100% {
    height: $line_width;
    top: -$line_width * .5;
  }
  45%, 55% {
    height: $line_width * 6;
    top: -$line_width * 3.1;
  }
}
@-webkit-keyframes piece-0 {
  0%, 10%, 90%, 100% {
    height: $line_width;
    top: -$line_width * .5;
  }
  45%, 55% {
    height: $line_width * 3;
    top: -$line_width * 1.5;
  }
}
@keyframes piece-0 {
  0%, 10%, 90%, 100% {
    height: $line_width;
    top: -$line_width * .5;
  }
  45%, 55% {
    height: $line_width * 3;
    top: -$line_width * 1.5;
  }
}
