.reviewTitle {
    text-transform: uppercase;
    font-size:12px;
    font-weight:300;
}

.reviewsubTitle {
    font-size:16px;
    font-weight: 500;
    margin-bottom:10px;
}

.ratingLoading {
    
    & .buttonSuccess {
        background-color: green;
        &:hover {
            background-color: green;
        }
      }
    & .fabProgress {
        color: green;
        position: absolute;
        top: -6;
        left: -6;
        z-index: 1;
      }
}

.buttonProgress {
  color: green !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}