@import "./variables/colors.scss";
@import "./fonts/fontsizes";
@import "./fonts/textTransforms";
@import "./flex/flex";
@import "./inputs/checkbox.scss";
@import "./review/review";
@import "./vouchers/voucher";
@import "./loaders/mainLoader.scss";
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  color: $mainColor;
  font-family: 'Work Sans', sans-serif;
  background-color: #fafafa !important;
}

pre {
  font-family: 'Work Sans', sans-serif;
}

.relative {
  position:relative;
}

.mainColor {
  color: $mainColor;
}

.noOutlineBorder {
  outline: 0;
  border: none;
}

.posRelative {
  position: relative;
}

.influencerProfileHeader {
  min-width: 50vw;
  max-width: 70vw;
  margin: 0 auto !important;
}

.commentMain {
  background-color: #eaeaea;
  padding: 0 0 100px 0;
}

.initials-avatar {
  width: 48px;
  height: 48px;
  cursor: default;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  background-color: $mainColor;
  font-weight: 700;
  display: inline-block;
  box-sizing: border-box;
}

.comment-Card-subtitle {
  color: #9c9c9c;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.comment-Card-actions {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
  align-items: center !important;
  padding: 20px !important;
}

.clockIcon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-top: 2px;
}

.MuiDialog-container .MuiDialog-paperFullScreen {
  background-color: #FAFBFB !important;
}

.floatingActionButton {
  position: fixed!important;
  bottom: 5%;
  right: 3%;
}

.statusLabel {
  max-width: 300px !important;
  white-space: break-spaces !important;
  overflow-wrap: break-word !important;
  max-height: 220px !important;
  height: 100% !important;
  width: 100% !important;
  min-width: 100px !important;
}

.actionCell {
  min-width:200px;
}

.tableTabs {
  color:black !important;
  border:none !important;
  box-shadow: none !important;
  border-bottom: none;
  border-radius: 0px;
  margin-bottom:10px;
}

.profileComment {
  background-color:#f4f4f4 !important;
  padding:10px;
  max-width:80%;
}

.backdropIMT {
    z-index: 9999999 !important;
    color: #fff;
    background: rgba(0,0,0,0.8);
    font-size: 18px;
}

.MuiFab-primary {
  color: #fff;
  background-color: $secondaryColor !important;
}

.buttonProgress {
      color: green;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -12;
      margin-left: -12;
}

.language-select {
  
  &:before {
    border: none !important
  }

  &:hover {
    border: none !important
  }

  &:after{
    border: none !important
  }
}

#language-switcher-select {
  padding-bottom: 0 !important;
}