@import '../variables/colors.scss';

.check5050Primary{
    width: 50px;
    height:50px;
    stroke:$mainColor;
}

.ratingCheckbox {
    & .MuiFormControlLabel-label {
        font-size: 12px !important;
        font-weight: 300 !important;
        text-transform: uppercase !important;
    }
}