.voucherTitle {
    font-weight:300;
    font-size:16px;
}

.voucherSubtitle {
    font-weight: 500;
    font-size: 16px;
}

.voucherMoneyTitle {
    font-weight: 700;
    font-size: 27px;
}

.voucherMoneySubtitle {
    font-weight: 300;
    font-size: 27px;
    margin-left: 0px;
}