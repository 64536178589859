body {
  margin: 0;
  padding: 0;
  background-color: rgb(238, 238, 238);
}

.MuiPaper-root {
  background: white !important;
}

.MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: #e9b9c4 !important;
  color: white !important;
}

.MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {
  background-color: #e9b9c4 !important;
  color: white !important;
}

.MuiDataGrid-root .MuiDataGrid-row:hover {
  background-color: #e9b9c4 !important;
  color: white !important;
}

.file-upload-result {
  margin-top: 20px;
  width: 500px;
  border: 1px solid lightgray;
  border-radius: 5px;
  position: relative;
  padding: 15px 10px;
}

.file-upload-result .file i.delete {
  margin-left: 12px;
  cursor: pointer;
  color: #e9b9c4;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.MuiAlert-standardSuccess {
    color: rgb(30, 70, 32) !important;
    background-color: rgb(237, 247, 237) !important;
}

.MuiAlert-standardError{
    background-color: rgb(255, 231, 236) !important;
}


.registerText {
    display: flex;
    color:#9C9C9C;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    padding:18.5px 4px;
    margin:10px 0 0 0 ;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size:16px;
}

.influencerProfileInput input {
  color: #333;
}