.flex{
    display: flex;
}

.f-column {
    flex-direction: column;
}

.f-row {
    flex-direction: row;
}

.flexColumn{
    display:flex;
    flex-direction: column;
}

.flexRow{
    display: flex;
    flex-direction: column;
}

.flexEnd{
    display: flex;
    justify-content: flex-end;
}

.flexStart{
    display: flex;
    justify-content: flex-start;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexBetween{
    display:flex;
    justify-content: space-between;
}

.centerSelf{
    justify-self: center;
    align-items: center;
}

.alignItemsCenter {
    align-items: center;
}

.flowRowWrap {
    flex-flow: row wrap;
}
